<template>
  <v-form>
    <v-text-field label="Imię" :rules="[v => !!v || 'Imię wymagane']" v-validate="'required|min:5|max:20'" type="text" dense outlined></v-text-field>
    <v-text-field
    
    type="email"
     
    :rules="emailRules"
    required
    label="Email" dense outlined></v-text-field>
    <v-text-field :rules="[v => !!v || 'Temat wiadomości wymagany']" v-validate="'required|min:5|max:20'" type="text" label="Temat" dense outlined></v-text-field>
    <v-textarea
      dense
      label="Treść wiadomości"
      
      outlined
      rows="5"
      row-height="20"
      :rules="messagerules"
       counter
       maxlength="250"
    ></v-textarea>
    <v-btn outlined block :disabled="true" color="primary">Wyślij</v-btn>
  </v-form>
</template>
 
<script>
 
export default {
   data: () => ({
    messagerules: [
    v => !v || v.length <= 250 || 'Maksymalnie 250 znaków',
    v => !!v || 'Treść wiadomości wymagana'
  ]
    ,
    emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Niepoprawny adres email',
        v => !!v || 'Adres email wymagany'
      ],
      
  }),
  
};
</script>
