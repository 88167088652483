<template>
  <nav
  absolute
  
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
  >
    <v-toolbar>
<!--      <v-toolbar-side-icon></v-toolbar-side-icon> -->
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <span class="font-weight-light">Auto</span>
        <span>Wjazd</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="currentUser">
        <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <v-icon left>mdi-dots-grid</v-icon>
          
        </v-btn>
        </template>
        <v-list >
          <!-- v-list-tile is changed to v-list-item -->
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-item-title>{{ link.text }}</v-list-item-title>
            <v-icon right>{{link.icon}}</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="currentUser" text href @click.prevent="logOut">
          <span>Wyloguj</span>
          <v-icon right>mdi-logout</v-icon>
        </v-btn>
       
      <v-btn v-if="!currentUser && this.$route.path!='/login'" text href @click.prevent="logIn">
      <span>Zaloguj</span>
          <v-icon right>mdi-login</v-icon>
      </v-btn>
      <v-btn v-if="!currentUser && this.$route.path!='/home' && this.$route.path!='/'" text href @click.prevent="homePage">
      <span>Strona główna</span>
          <v-icon right>mdi-home</v-icon>
      </v-btn>

       <v-btn icon @click="changeThemeColor">
        <v-icon>{{
          $vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-weather-night'
        }}</v-icon>
        </v-btn>
         
    </v-toolbar>

    <v-navigation-drawer app v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-title class="title">
      <v-img
           :src="`https://dane.auto-wjazd.pl/imageraw/LogoAutoWjazd_WEBP.webp`"
           :lazy-src="`https://dane.auto-wjazd.pl/imageraw/LogoAutoWjazd_WEBP.webp`"
           alt="AUTO-WJAZD.PL- zarządzanie wjazdami na teren firmy, parkingów. Automatyczne odczyty tablic rejestracyjnych z wykorzystaniem sztucznej inteligencji."
           ></v-img>
        </v-list-item-title>
        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <v-list nav v-if="currentUser">
        <v-list-item v-for="menu in menus" :key="menu.title" :to="menu.url">
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-else>
      <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      Należy się zalogować.
    </v-alert>
      </template>
      
    </v-navigation-drawer >
  </nav>
</template>


<script>
export default {
  name: 'Navbar-comp',
  data: () => ({
    drawer: false,

    menus: [
      { title: 'Strona główna', icon: 'mdi-home', url: '/' },
      { title: 'Odczyty analityki', icon: 'mdi-camera-control', url: '/entrances' },
      { title: 'Kontrola dostępu', icon: 'mdi-credit-card-plus-outline', url: '/access-control' },
      { title: 'Harmonogram', icon: 'mdi-calendar-edit', url: '/scheduler' },
      
      { title: 'Moduły', icon: 'mdi-electric-switch', url: '/modules' },
      { title: 'Bramy', icon: 'mdi-garage-alert-variant', url: '/gates-cameras' },
      { title: 'Użytkownicy', icon: 'mdi-account-multiple', url: '/user-management' }
    ],
    links: [
       
      { icon: 'mdi-account', text: 'Konto', route: '/profile'},
    ]
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
      changeThemeColor() {
       
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

     localStorage.setItem('theme', this.$vuetify.theme.dark ? 'dark' : 'light');
    },
        
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    logIn() {
      
      this.$router.push('/login');
    },
    homePage() {

      this.$router.push('/home');
    },
  },
}
</script>