<template>

  <v-app>
    <v-card class="overflow-hidden">
    <Navbar />
    
      <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
      max-height="auto"
    >
      <router-view></router-view>
    
    <v-footer fixed >
    <v-col
    class="d-flex justify-center text-center"
      cols="12"
    >
     Copyright &copy; {{ new Date().getFullYear() }}
    
     <v-divider    
  vertical
  class="mx-4"
></v-divider>
  <v-img position="center"
           max-width="100"
           :src="`https://dane.auto-wjazd.pl/imageraw/autoWjazdPlate.w100px.webp`"
           :lazy-src="`https://dane.auto-wjazd.pl/imageraw/autoWjazdPlate.w100px.webp`"
           alt="AUTO-WJAZD.PL- zarządzanie wjazdami na teren firmy, parkingów. Automatyczne odczyty tablic rejestracyjnych z wykorzystaniem sztucznej inteligencji."
           >
   </v-img>
   </v-col>
  </v-footer>
</v-sheet>
</v-card>
</v-app>
</template>


<script>
import Navbar from '@/components/Navbar'
  

export default {
  name: 'App',
  components: { Navbar},
   data: () => ({
    drawer: false,
    overlay: true,
     
  }),
  computed: {
     
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
 
};
</script>
