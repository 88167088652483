<template>
  <v-app>
  <v-form name="form" @submit.prevent="handleLogin">
    <v-card width="550" class="mx-auto mt-5">
      <v-card-title class="pb-0">
        
        <v-img
           :src="`https://dane.auto-wjazd.pl/imageraw/LogoAutoWjazd_WEBP.webp`"
           :lazy-src="`https://dane.auto-wjazd.pl/imageraw/LogoAutoWjazd_WEBP.webp`"
           alt="AUTO-WJAZD.PL- zarządzanie wjazdami na teren firmy, parkingów. Automatyczne odczyty tablic rejestracyjnych z wykorzystaniem sztucznej inteligencji."
           ></v-img>
       
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        
          <v-text-field
          data-vv-name="username"
            v-model="user.username"
            v-validate="'required'"
            label="Nazwa użytkownika" 
            prepend-icon="mdi-account-circle"
            
          />
           
         <v-text-field
         data-vv-name="password"
           v-model="user.password"
            v-validate="'required'"
            :type="showPassword ? 'text' : 'password'"
            label="Hasło"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
           
          />
           
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
       
        <v-btn color="info" :disabled="loading" @click="handleLogin">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Login</span>
        </v-btn>
        
      </v-card-actions>
       <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
    </v-card>
    </v-form>
  </v-app>
</template>
<script>
import User from '../models/user';

export default {
  name: 'Login-Page',
  data() {
    return {
      user: new User('', ''),
      
      loading: false,
      message: '',
      showPassword: false,
      color: '#cc181e',
      color1: '#5bc0de',
      size: '45px',
      margin: '2px',
      radius: '2px',
    };
  },
   
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  watch: {
      overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 3000)
      },
    },
  methods: {
    handleLogin() {
      this.loading = true;
        

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/entrances');
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
                
            }
          );
        }
       
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>