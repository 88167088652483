<template>
  <section
    id="footer-top"
    style="margin-bottom:10px;"
    :class="
      $vuetify.theme.dark
        ? 'blue-grey darken-4 grey--text text--lighten-1'
        : 'grey lighten-3 grey--text text--darken-4'
    "
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="5" class="py-12"
          ><v-row no-gutters>
            <v-col cols="12">
          <v-img
           :src="`https://dane.auto-wjazd.pl/imageraw/LogoAutoWjazd_WEBP.webp`"
           :lazy-src="`https://dane.auto-wjazd.pl/imageraw/LogoAutoWjazd_WEBP.webp`"
           alt="AUTO-WJAZD.PL- zarządzanie wjazdami na teren firmy, parkingów. Automatyczne odczyty tablic rejestracyjnych z wykorzystaniem sztucznej inteligencji."
           ></v-img>
            </v-col>
            <v-col cols="12">
              <p class="mt-8 text-justify" style="max-width: 400px">

              </p>
            </v-col>
            <v-col cols="12">
              <v-list two-line class="transparent">

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>EMAIL</v-list-item-title>
                    <v-list-item-subtitle
                      >info@auto-wjazd.pl</v-list-item-subtitle
                    >

                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>TELEFON</v-list-item-title>
                    <v-list-item-subtitle>(+48) 501-071-578</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
              <div class="d-flex justify-center mt-5">
                <v-btn
                  v-for="(socialm, i) in social"
                  :key="`social-${i}`"
                  class="d-flex"
                  icon
                  large
                  color="primary"
                  :href="socialm.link"
                  target="_blank"
                >
                  <v-icon>{{ socialm.icon }}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row></v-col
        >
        <v-col cols="1" class="text-center hidden-sm-and-down col col-2 py-12">
          <v-divider vertical></v-divider>
        </v-col>
        <v-col cols="12" md="5" class="py-12">
          <h3 class="mb-8">WYŚLIJ WIADOMOŚĆ</h3>
          <footerContactForm />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import footerContactForm from './sections/footerContactForm.vue';

export default {
  name: 'FooterTop-section',
  components: {
    footerContactForm,
  },
  data() {
    return {
      social: [
        {
          platform: 'Facebook',
          link: '#',
          icon: 'mdi-facebook',
        },
        {
          platform: 'Twitter',
          link: '#',
          icon: 'mdi-twitter',
        },
        {
          platform: 'Instagram',
          link: '#',
          icon: 'mdi-instagram',
        },
        {
          platform: 'Linkedin',
          link: '#',
          icon: 'mdi-linkedin',
        },

      ],
    };
  },
};
</script>
