import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ValidationProvider } from 'vee-validate';
import VueNativeNotification from 'vue-native-notification'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
 
Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})
 
/*if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });
} */

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
