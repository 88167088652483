<template>
  <section class="primary darken-1 white--text py-16">
    <v-container>
      <v-row>
        <v-col class="text-center">
          <h2 class="text-h4 text-md-h3 text-center font-weight-black">
            Dostęp do systemu AUTOWJAZD i zarządzania wjazdami na 3 sposoby!
          </h2>
          <div
            class="text-h5 text-md-h4 text-center font-weight-black py-3 mb-10"
          >
            Dla Twojej wygody i szybkości działania.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(card, index) in cards"
          :key="index"
          cols="12"
          sm="4"
          class="text-center"
        >
          <v-avatar
            size="80"
            class="display-1 white font-weight-bold primary--text mb-5"
            style="opacity: 1.15"
            >{{ card.callout }}</v-avatar
          >
          <div class="title text-uppercase mt-1 mb-4" v-text="card.title"></div>

          <p v-text="card.text"></p>
          <v-row no-gutters>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-btn x-large rounded color="white primary--text mx-auto mt-10 px-16">
          <v-icon dark left> mdi-check-bold </v-icon>Wykup swój plan już teraz!
        </v-btn>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Features-section',
  data() {
    return {
      cards: [
        {
          title: 'W przeglądarce Internetowej',
          text: 'Uniwersalne rozwiązanie. Możliwość zarządzania wjazdami z poziomu komputera oraz telefonu. Dostęp do archiwalnych oraz bierzących odczytów tablic rejestracyjnych pojazdów. ',
          callout: '01',
        },
        {
          title: 'Aplikacja komputerowa',
          text: 'Aplikacja polecana na wjazdy obsługiwane przez lokalną obsługę w recepcji lub ochronę. Wgląd na odczyty tablic rejestracyjnych w sposób stały i zarządzanie wjazdami na teren firmy, osiedla lub parkingu.',
          callout: '02',
        },
        {
          title: 'Dedykowana aplikacja mobilna',
          text: 'Aplikacja kliencka dla telefonów z systemem iOS oraz Android.',
          callout: '03',
        },

      ],
    };
  },
};
</script>
