import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pl from 'vuetify/lib/locale/pl';

Vue.use(Vuetify);
 
export default new Vuetify({
  theme: {
dark: localStorage.getItem('theme') === 'dark',
cookies: localStorage.getItem('ciastka') === true,
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    lang: {
      locales: { pl },
      current: 'pl',
    },
});
