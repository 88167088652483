<template>
  <section id="hero">
    <v-carousel fluid
      height="calc(100vh - 84px)"

      dark
      cycle
      show-arrows-on-hover
      draggable="true"
      hide-delimiter-background
    >
      <v-carousel-item
        v-for="(carousel, carindex) in carouselsData"
        :key="carindex"
        :src="`https://dane.auto-wjazd.pl/imageraw/`+carousel.src"
        class="gradient-fill"
        :alt="carousel.src"

      >
        <v-container fill-height>
          <div style="max-width: 600px">
            <div
              class="text-md-h2 mb-3 text-sm-h3 text-h5 font-weight-black white--text"
            >
              {{ carousel.heading.toUpperCase() }}
            </div>
            <p class="mb-5 white--text">{{ carousel.subHeading }}</p>

            <span class="mx-2 my-4"></span>
            <v-btn
              :x-large="$vuetify.breakpoint.smAndUp"
              text
              class="my-3"
              outlined
              dark
              ><v-icon left large color="primary">mdi-play</v-icon>Zobacz więcej
              </v-btn
            >
          </div>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
export default {
  name: 'Heroe-section',
  data() {
    return {
      carouselsData: [
        {
          src: 'autowjazd-harmonogram_przyjazdow.webp',
          heading: 'Nowość! Harmonogram przyjazdów! ',
          subHeading:
            ' Możliwość zaplanowania przyjazdu pojazdu. Automatyczne powiadomienia dla pojazdów dodanych do harmonogramu. ',
        },
        {
          src: 'AutoWjazd-LPR_Automatyczny_odczyt_tablicy_rejestracyjnej.webp',
          heading: ' Ewidencja czasu pobytu na parkingu ',
          subHeading:
            ' AUTO-WJAZD.PL - System do wspomagania zarządzania parkingami, wjazdami na teren prakingu, osiedla, firmy. ',
        },
        {
          src: 'AutoWjazd_AutomatyczneOdczyty_Tablic_rejestracyjnych.webp',
          heading: ' AUTOWJAZD-LPR ',
          subHeading:
            ' Autorskie oprogramowanie komputerowe. Źródło odczytów tablic rejestracyjnych pojazdów, oparte o sztuczną inteligencję.',
        },
        {
          src: 'AutoWjazd-PL_panel_sterowania_modulem_elektronicznym.webp',
          heading: ' Panel danych na żywo ',
          subHeading:
            ' Wizualizacja danych z analityki AUTOWJAZD-LPR w czasie rzeczywistym. Możliwość sterowania modułem elektronicznym z poziomu strony WWW na komputerze i telefonie.',
        },
        {
          src: 'AutoWjazd_AutomatyczneOdczyty_Tablic_rejestracyjnych.webp',
          heading: ' AUTOWJAZD-KD ',
          subHeading:
            ' Moduł programowy dla systemu AUTO-WJAZD.PL. Kontrola dostępu przez zarządzanie przejściami, na podstawie odczytów kart lub pastylek RFID.',
        },

      ],
    };
  },
};
</script>

<style>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(3, 12, 41, 0.80),
    rgba(5, 11, 31, 0.65)
  );
}
</style>
