import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/scheduler',
    name: 'scheduler',
    component: () => import('../views/Scheduler.vue'),
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/profile',
    name: 'profile',
    // lazy-loaded
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/user-management',
    name: 'admin',
    // lazy-loaded
    component: () => import('../views/BoardUsers.vue'),
  },
  {
    path: '/gates-cameras',
    name: 'moderator',
    // lazy-loaded
    component: () => import('../views/BoardGates.vue'),
  },
  {
    path: '/entrances',
    name: 'user',
    // lazy-loaded
    component: () => import('../views/BoardEntrances.vue'),
  },
  {
    path: '/access-control',
    name: 'user',
    // lazy-loaded
    component: () => import('../views/BoardAccessControl.vue'),
  },
  {
    path: '/modules',
    name: 'modules',
    // lazy-loaded
    component: () => import('../views/BoardModules.vue'),
  },
  {
    // catch all 404 - define at the very end
    path: '*',
    component: () => import('../views/NotFound.vue'),
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/home');
  } else {
    next();
  }
});

export default router;
