<template>
  <section id="intro" class="py-16">
    <v-container>
      <v-responsive class="max-auto mx-auto text-center" max-width="600">
        <v-avatar color="primary" size="70" class="mb-8">
          <v-icon x-large dark>mdi-web</v-icon>
        </v-avatar>
        <h2 class="text-h6 text-md-h3 text-center font-weight-black mb-7">
          AUTOWJAZD- system zarządzania wjazdami na teren posesji, firmy lub parkingu.
          Ewidencja czasu przebywania pojazdów w strefie parkingowej z wykorzystaniem automatycznych odczytów tablic rejestracyjnych.
        </h2>
        <p class="title font-weight-light">
          AUTOWJAZD-LPR — autorskie oprogramowanie komputerowe, realizujące odczyty tablic rejestracyjnych z wykorzystaniem sztucznej inteligencji.
          Integracja z przemysłowymi modułami elektronicznymi, realizującymi otwarcie i zamknięcie bramy lub szlabanu.
        </p>
      </v-responsive>

      <v-row class="pt-12">
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <v-row no-gutters>
            <v-col :cols="card.callout ? 9 : 12">
              <div class="pr-2">
                <div class="text--disabled" v-text="card.subtitle"></div>
                <h4
                  class="text-uppercase mt-1 mb-4"
                  style="letter-spacing: 0.15em"
                  v-text="card.title"
                ></h4>
                <p v-text="card.text"></p>
              </div>
            </v-col>
            <v-col v-if="card.callout" cols="2">
              <span
                class="text-h3 grey--text font-weight-bold pr-8"
                style="opacity: 0.1"
                >{{ card.callout }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Intro-section',
  data() {
    return {
      cards: [
        {
          title: 'Produktywność',
          subtitle: 'Profesjonalne podejście',
          text: 'Wszystkie komponenty i funkcje są opracowywane przy użyciu najnowocześniejszych technologii.',
          callout: '01',
        },
        {
          title: 'Szybkie i zoptymalizowane',
          subtitle: 'Wydajność',
          text: 'Optymalizacja rozwiązań dla efektywniejszego i ekonomicznego użytkowania.',
          callout: '02',
        },
        {
          title: 'Użytkowanie',
          subtitle: 'Prostota',
          text: 'Tworzone z myślą o użytkowniku.',
          callout: '03',
        },
      ],
    };
  },
};
</script>
