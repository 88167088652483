<template>
 
  <v-app dark>
   
     <v-main>
      <v-col>
    <SectionsHero />
    <SectionsIntro />
    <SectionsFeatures />
    <SectionsCalloutBlock />
    <footerTop />
    <Notification />
  </v-col>
    </v-main>
  </v-app>
</template>


<script>
  
import footerTop from '../components/footerTop.vue'
//import siteFooter from '../components/siteFooter.vue'
//import Notification from '../components/Notification.vue' //Cookies notifi
import SectionsHero from '../components/sections/Hero.vue'
import SectionsIntro from '../components/sections/Intro.vue'
import SectionsFeatures from '../components/sections/Features.vue'
import SectionsCalloutBlock from '../components/sections/CalloutBlock.vue' 

export default {
  name: 'Home-Page',
  components: {
    
    footerTop,
    SectionsHero,
    SectionsIntro,
    //siteFooter,
    SectionsFeatures,
    SectionsCalloutBlock,
    //Notification,
  },
  
  data() {
    return {
      
   
    };
  },
   
};
</script>

